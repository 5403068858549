// Ck Editor
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    Heading,
    Italic,
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { Form } from "react-bootstrap";

const Description= ({formik}) => {

    // For Ck Editor
    const editorContainerRef=useRef(null);
    const editorRef=useRef(null);
    const [isLayoutReady, setIsLayoutReady]=useState(false);    
    
    // Editor configuration
    const editorConfig={
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "|",
                "alignment"
            ],
            shouldNotGroupWhenFull: false,
        },
        alignment: {
            options: ['left', 'right', 'center', 'justify']
        },
        plugins: [
            Autoformat,
            Autosave,
            Bold,
            Essentials,
            Heading,
            Italic,
            Paragraph,
            List,
            TextTransformation,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Alignment,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },
        initialData: "",
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };

    // Setting layout for Ckeditor
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    return (
        <div className="mt-3 mb-5">
            {/* Description */}
            {/* <div className='panel-type-1'>
                Description
            </div> */}
            <div className="col-xl-12 col-md-12 mb-12 ">
                                            <Form.Group controlId="facility_description">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                 placeholder="Enter Your Text..."
                                                    name="facility_description"
                                                    rows={4}
                                                    value={formik?.values?.facility_description}
                                                    onChange={formik?.handleChange}
                                                />
                                                {formik?.touched?.facility_description && formik?.errors?.facility_description ?
                                                    <div className='error-msg'>{formik?.errors?.facility_description}</div>
                                                    : <></>}
                                            </Form.Group>
                                        </div>
        </div>
    )
}

export default Description