import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Layout from './common/layout/Layout'
import { BESPOKE_BOOKINGS, BOOKINGS, BRANDS, CLUBS_FACILITIES, CLUBS_INFORMATION, CLUBS_PROMOTIONS, CREATE_BRANDS, CREATE_CLUBS_INFORMATION, CREATE_CURATED_MEMBER_EVENTS, CREATE_CURATED_SPONSORSHIP, CREATE_PRIVATE_EVENTS, CREATE_SPECIAL_SUBSCRIBERS, CREATE_SURVEY_QUESTIONER, CURATED_MEMBER_EVENTS, CURATED_SPONSORSHIP, HOME, MEMBER_STATS, PRIVATE_EVENTS, RECIPROCAL_REQUESTS, SPECIAL_SUBSCRIBERS, SURVEY_QUESTIONER, VIEW_BOOKINGS, VIEW_CLUBS_INFORMATION, VIEW_SURVEY_QUESTIONER, VIEW_RECIPROCAL_REQUESTS, INTERESTS, PLACES, CREATE_INTERESTS, CREATE_PLACES, PUSH_NOTIFICATIONS, PUSH_NOTIFICATIONS_CATEGORY, CREATE_PUSH_NOTIFICATIONS, CREATE_PUSH_NOTIFICATIONS_CATEGORY, OFFER, CREATE_OFFER_NOTIFICATION, CLUB_OFFER_ADD, NOTIFICATIONS, SYNC_CONTACT, CORPORATE, CREATE_CORPORATE, CREATE_CLUBS_PROMOTIONS, LETTER_OF_INTRODUCTION, CREATE_LETTER_OF_INTRODUCTION, MANAGE_MEMBERS, MANAGE_ADMINS, CREATE_ADMIN, CREATE_CLUBS_FACILITY, FACILITIES_CATEGORY, MOBICOM_WEB, ADD_CLUB, PLATINUMCLUBNET, PLATINUMCLUBNETADD, CREATE_FACILITIES_CATEGORY, SOLITAIRE_NETWORK, CREATE_SOLITAIRE_NETWORK, CLUB_PROFILE, CREATE_CLUB_PROFILE, EDIT_CLUB, EDIT_FACILITIES_CATEGORY, LIST_BRAND_CATEGORY, ADD_BRAND_CATEGORY, EDIT_BRAND_CATEGORY, EDIT_BRANDS, EDIT_INTEREST, EDIT_PLACES, CLUB_ONBOARDIN, CLUB_ONBOARDING_LIST, USER_MANAGEMENT, GROUPS, ADD_ROLE, EDIT_ROLE, EDIT_GROUP, ADD_GROUP, USERS, ADD_USER, EDIT_USER, VIEW_CLUINFO, CLUB_PROFILES, EDIT_CLUB_ONBOARDING, VIEW_MEMBER_INFO, EDIT_CLUB_INFORMATIONS, NOTIFICATION_DETAIL, MANAGE_NETWORK_CLUBS, AFFILIATED_CLUBS, ADD_AFFILIATED_CLUBS, CURATION_PROFILE, CURRENT_CLUB_EVENTS, CLUB_EVENTS_PROFILE, PRIVATE_EVENTS_PROFILE, PROMOTIONS, SUBSCRIPTION_PRIVILEGES, ADD_SUBSCRIPTION_PRIVILEGES, EDIT_SUBSCRIPTION_PRIVILEGES, BLACKOUT_DATES, GPCA_OVERVIEW, MEMBERREVIEWLIST, VIEW_MEMBER_REVIEW, CLUB_TYPE_LIST, ADD_CLUB_TYPE, EDIT_CLUB_TYPE, SUBSCRIPTIONTYPELIST, ADD_SUBSCRIPTIONTYPE, EDIT_SUBSCRIPTIONTYPE, CLUB_PROFIL, USER_SUBSCRIPTION_LIST } from './constants/pathname'

import Home from './pages/Home/Home'
import MemberStatsPage from './pages/MemberStats/MemberStats'
import ClubsInformationPage from './pages/ClubsInformation/ClubsInformation'
import CreateClubsInformation from './pages/ClubsInformation/CreateClubsInformation'
import ViewClubInformationPage from './pages/ViewClubInformation/ViewClubInformationPage'
import ClubsFacilitiesPage from './pages/ClubsFacilities/ClubsFacilitiesPage'
import CuratedSponsorshipPage from './pages/CuratedSponsorship/CuratedSponsorshipPage'
import CreateCuratedSponsorshipPage from './pages/CreateCuratedSponsorship/CreateCuratedSponsorshipPage'
import CuratedMemberEventsPage from './pages/CuratedMemberEvents/CuratedMemberEvents'
import CreateCuratedMemberEventsPage from './pages/CuratedMemberEvents/CreateCuratedMemberEvents'
import PrivateEventsPage from './pages/PrivateEvents/PrivateEventsPage'
import CreatePrivateEventsPage from './pages/PrivateEvents/CreatePrivateEventsPage'
import SurveyQuestionerPage from './pages/SurveyQuestioner/SurveyQuestionerPage'
import CreateSurveyQuestionerPage from './pages/CreateSurveyQuestioner/CreateSurveyQuestionerPage'
import ClubsPromotionsPage from './pages/ClubsPromotions/ClubsPromotionsPage'
import BrandsPage from './pages/Brands/BrandsPage'
import CreateBrandsPage from './pages/Brands/CreateBrandsPage'
import ViewSurveyQuestionerPage from './pages/ViewSurveyQuestioner/ViewSurveyQuestionerPage'
import SpecialSubscribersPage from './pages/SpecialSubscribers/SpecialSubscribersPage'
import CreateSpecialSubscribersPage from './pages/SpecialSubscribers/CreateSpecialSubscribersPage'
import BespokeBookingsPage from './pages/Bookings/BespokeBookings'
import MemberRequestsPage from './pages/Bookings/MemberRequests'
import ViewMemberRequestPage from './pages/Bookings/ViewMemberRequest'
import ReciprocalRequestsPage from './pages/ReciprocalRequests/ReciprocalRequestsPage'
import ViewReciprocalRequestsPage from './pages/Bookings/ViewReciprocalRequests'
import InterestsPage from './pages/Interests/InterestsPage'
import PlacesPage from './pages/Places/PlacesPage'
import CreateInterestPage from './pages/CreateInterest/CreateInterestPage'
import CreatePlacesPage from './pages/CreatePlaces/CreatePlacesPage'
import PushNotificationsPage from './pages/PushNotifications/PushNotificationsPage'
import PushNotificationsCategoryPage from './pages/PushNotificationsCategory/PushNotificationsCategoryPage'
import CreatePushNotificationsPage from './pages/CreatePushNotifications/CreatePushNotificationsPage'
import CreatePushNotificationsCategory from './components/CreatePushNotificationsCategory/CreatePushNotificationsCategory'
import Offer from './pages/Offer/Offer'
import CreateOfferNotificationPage from './pages/CreateOfferNotification/CreateOfferNotificationPage'
import ClubOfferAddPage from './pages/ClubOfferAdd/ClubOfferAddPage'
import Notifications from './pages/Notifications/Notifications'
import SyncContactPage from './pages/SyncContact/SyncContactPage'
import CorporatePage from './pages/Corporate/CorporatePage'
import CreateCorporatePage from './pages/Corporate/CreateCorporatePage'
import CreateClubsPromotionsPage from './pages/ClubsPromotions/CreateClubsPromotions'
import LetterOfIntroductionPage from './pages/LetterOfIntroduction/LetterOfIntroduction'
import CreateLetterOfIntroductionPage from './pages/CreateLetterOfIntroduction/CreateLetterOfIntroductionPage'
import ManageMembersPage from './pages/ManageMembers/ManageMembersPage'
import ManageAdminsPage from './pages/ManageAdmins/ManageAdmins'
import CreateAdminPage from './pages/ManageAdmins/CreateAdmin'
import CreateClubsFacilitiesPage from './pages/ClubsFacilities/CreateClubsFacilitiesPage'
import FacilitiesCategoryPage from './pages/FacilitiesCategory/FacilitiesCategoryPage'
import ClubListing from './components/Web/Club/clubListing'

import PlatinumClubNet from './components/Web/Pcn/PlatinumClubNet'
import PlatinumClubNetadd from './components/Web/Pcn/PlatinumClubNetadd'
import AddClub from './components/Web/Club/AddClub'
import CreateFacilityCategoryPage from './components/FacilitiesCategory/CreateFacilityCategory'
import SolitaireNetworkPage from './pages/SolitaireNetwork/SolitaireNetwork'
import CreateSolitaireNetworkPage from './pages/SolitaireNetwork/CreateSolitaireNetwork'
import ClubProfilePage from './pages/ClubProfile/ClubProfilePage'
import CreateClubProfilePage from './pages/ClubProfile/CreateClubProfilePage'
import BrandCategoryList from './pages/AddBrandCategory/BrandCategoryList'
import AddBrandCategory from './pages/AddBrandCategory/AddBrandCategory'
import ClubOnboarding from './components/ClubOnboarding/ClubOnboarding'
import ClubOnboardingList from './components/ClubOnboarding/ClubOnboardingList'
import Index from './pages/userManagement'
import Groups from './components/UserManagement/Groups'
import AddRole from './components/UserManagement/AddEdit/AddOrEditRole'
import AddOrEditGroup from './components/UserManagement/AddEdit/AddOrEditGroup'
import Users from './components/UserManagement/users'
import AddOrEditUser from './components/UserManagement/AddEdit/AddOrEditUser'
import ViewClubinfo from './components/ClubOnboarding/ViewClubinfo'
import ClubProfile from './pages/Bookings/ClubProfile'
import ViewMemberInfo from './components/CreateSpecialSubscribers/ViewMemberInfo'
import NotificationDetail from './pages/Notifications/NotificationDetail'
import ManageNetworkClubs from './pages/ManageNetworkClubs/ManageNetworkClubs'
import CurationProfile from './pages/CurationProfile/CurationProfile'
import CurrentClubEvents from './pages/CurrentClubEvents/CurrentClubEvents'
import ClubEventsProfile from './pages/ClubEventsProfile/ClubEventsProfile'
import PrivateEventsMenu from './pages/PrivateEventsMenu/PrivateEventsMenu'
import Promotions from './pages/Promotions/Promotions'
import AffiliatedClubs from './pages/AffiliatedClubs'
import AddAffiliatedClubs from './pages/AffiliatedClubs/AddAffiliatedClubs'
import SubscriptionPrivilege from './components/Subscription/subscriptionPrivilege'
import ListPrivilege from './components/Subscription/listPrivilege'
import BlackoutDates from './pages/BlackoutDates/BlackoutDates'
import OverView from './pages/Overview/Overview'
import MemeberReviewList from './components/MemeberReviews/MemeberReviewList'
import ViewMemeberReview from './components/MemeberReviews/ViewMemeberReview'
import ClubtypeList from './components/ClubType/ClubtypeList'
import AddClubType from './components/ClubType/AddClubType'
import ListSubscriptionType from './components/SubscriptionType/ListSubscriptionType'
import AddsubscriptionType from './components/SubscriptionType/AddsubscriptionType'
import UserSubscriptionList from './pages/UserSubscribtion/UserSubscriptionList'



const PrivateRoutes = () => {

  const [log, setLog] = useState()
  const navigate = useNavigate();

  useEffect(() => {
    const logged = localStorage.getItem('is_logged');
    setLog(logged)
    if (!logged) {
      navigate('/login')
    }

  }, [])
  return (
    <Suspense fallback={<div style={{ height: '100vh' }}>Loading</div>}>
      {log &&
        <Routes>
          <Route exact path='/' element={<Layout />} >

            <Route exact path={HOME} element={<Home />}></Route>
            <Route exact path={MEMBER_STATS} element={<MemberStatsPage />}></Route>
            <Route exact path={MANAGE_MEMBERS} element={<ManageMembersPage />}></Route>
            <Route exact path={MANAGE_ADMINS} element={<ManageAdminsPage />}></Route>
            <Route exact path={CREATE_ADMIN} element={<CreateAdminPage />}></Route>
            <Route exact path={SYNC_CONTACT} element={<SyncContactPage />}></Route>
            <Route exact path={CLUBS_INFORMATION} element={<ClubsInformationPage />}></Route>
            <Route exact path={CLUB_PROFILE} element={<ClubProfilePage />}></Route>
            <Route exact path={CLUB_PROFIL + ":id"} element={<ClubProfilePage />}></Route>
            <Route exact path={CREATE_CLUB_PROFILE} element={<CreateClubProfilePage />}></Route>
            <Route exact path={LETTER_OF_INTRODUCTION} element={<LetterOfIntroductionPage />}></Route>
            <Route exact path={CREATE_LETTER_OF_INTRODUCTION} element={<CreateLetterOfIntroductionPage />}></Route>
            <Route exact path={CREATE_CLUBS_INFORMATION} element={<CreateClubsInformation />}></Route>
            <Route exact path={EDIT_CLUB_INFORMATIONS + ":id"} element={<CreateClubsInformation />}></Route>

            <Route exact path={VIEW_CLUBS_INFORMATION} element={<ViewClubInformationPage />}></Route>
            <Route exact path={MANAGE_NETWORK_CLUBS} element={<ManageNetworkClubs />}></Route>
            <Route exact path={GPCA_OVERVIEW} element={<OverView />}></Route>
            <Route exact path={CLUBS_FACILITIES} element={<ClubsFacilitiesPage />}></Route>
            <Route exact path={SOLITAIRE_NETWORK} element={<SolitaireNetworkPage />}></Route>
            <Route exact path={CREATE_SOLITAIRE_NETWORK} element={<CreateSolitaireNetworkPage />}></Route>
            <Route exact path={FACILITIES_CATEGORY} element={<FacilitiesCategoryPage />}></Route>
            <Route exact path={CREATE_FACILITIES_CATEGORY} element={<CreateFacilityCategoryPage />}></Route>
            <Route exact path={EDIT_FACILITIES_CATEGORY + ":id"} element={<CreateFacilityCategoryPage />}></Route>
            <Route exact path={CREATE_CLUBS_FACILITY} element={<CreateClubsFacilitiesPage />}></Route>
            <Route exact path={CURATED_SPONSORSHIP} element={<CuratedSponsorshipPage />}></Route>
            <Route exact path={CREATE_CURATED_SPONSORSHIP} element={<CreateCuratedSponsorshipPage />}></Route>
            <Route exact path={CURATED_MEMBER_EVENTS} element={<CuratedMemberEventsPage />}></Route>
            <Route exact path={CREATE_CURATED_MEMBER_EVENTS} element={<CreateCuratedMemberEventsPage />}></Route>
            <Route exact path={PRIVATE_EVENTS} element={<PrivateEventsPage />}></Route>
            <Route exact path={CREATE_PRIVATE_EVENTS} element={<CreatePrivateEventsPage />}></Route>
            <Route exact path={SURVEY_QUESTIONER} element={<SurveyQuestionerPage />}></Route>
            <Route exact path={CREATE_SURVEY_QUESTIONER} element={<CreateSurveyQuestionerPage />}></Route>
            <Route exact path={VIEW_SURVEY_QUESTIONER} element={<ViewSurveyQuestionerPage />}></Route>
            <Route exact path={CLUBS_PROMOTIONS} element={<ClubsPromotionsPage />}></Route>
            <Route exact path={CREATE_CLUBS_PROMOTIONS} element={<CreateClubsPromotionsPage />}></Route>
            <Route exact path={BRANDS} element={<BrandsPage />}></Route>
            <Route exact path={CREATE_BRANDS} element={<CreateBrandsPage />}></Route>
            <Route exact path={EDIT_BRANDS + ":id"} element={<CreateBrandsPage />}></Route>
            <Route exact path={SPECIAL_SUBSCRIBERS} element={<SpecialSubscribersPage />}></Route>
            <Route exact path={CREATE_SPECIAL_SUBSCRIBERS} element={<CreateSpecialSubscribersPage />}></Route>
            <Route exact path={VIEW_MEMBER_INFO + ":id"} element={<ViewMemberInfo />}></Route>
            <Route exact path={BOOKINGS} element={<MemberRequestsPage />}></Route>
            <Route exact path={`${VIEW_BOOKINGS}/:id`} element={<ViewMemberRequestPage />}></Route>
            <Route exact path={BESPOKE_BOOKINGS} element={<BespokeBookingsPage />}></Route>
            <Route exact path={RECIPROCAL_REQUESTS} element={<ReciprocalRequestsPage />}></Route>
            <Route exact path={`${VIEW_RECIPROCAL_REQUESTS}/:id`} element={<ViewReciprocalRequestsPage />}></Route>
            <Route exact path={INTERESTS} element={<InterestsPage />}></Route>
            <Route exact path={CREATE_INTERESTS} element={<CreateInterestPage />}></Route>
            <Route exact path={EDIT_INTEREST + ":id"} element={<CreateInterestPage />}></Route>
            <Route exact path={`${PLACES}/:id/:name`} element={<PlacesPage />} />
            <Route exact path={CREATE_PLACES} element={<CreatePlacesPage />}></Route>
            <Route exact path={EDIT_PLACES + ":id"} element={<CreatePlacesPage />}></Route>
            <Route exact path={PUSH_NOTIFICATIONS} element={<PushNotificationsPage />}></Route>
            <Route exact path={PUSH_NOTIFICATIONS_CATEGORY} element={<PushNotificationsCategoryPage />}></Route>
            <Route exact path={CREATE_PUSH_NOTIFICATIONS} element={<CreatePushNotificationsPage />}></Route>
            <Route exact path={CREATE_PUSH_NOTIFICATIONS_CATEGORY} element={<CreatePushNotificationsCategory />}></Route>
            <Route exact path={OFFER} element={<Offer />}></Route>
            <Route exact path={CREATE_OFFER_NOTIFICATION} element={<CreateOfferNotificationPage />}></Route>
            <Route exact path={CLUB_OFFER_ADD} element={<ClubOfferAddPage />}></Route>
            <Route exact path={NOTIFICATIONS} element={<Notifications />}></Route>
            <Route exact path={NOTIFICATION_DETAIL} element={<NotificationDetail />}></Route>
            <Route exact path={CORPORATE} element={<CorporatePage />}></Route>
            <Route exact path={CREATE_CORPORATE} element={<CreateCorporatePage />}></Route>
            <Route exact path={USER_MANAGEMENT} element={<Index />}></Route>
            <Route exact path={ADD_ROLE} element={<AddRole />}></Route>
            <Route exact path={`${EDIT_ROLE}/:id`} element={<AddRole />}></Route>
            <Route exact path={GROUPS} element={<Groups />}></Route>
            <Route exact path={ADD_GROUP} element={<AddOrEditGroup />}></Route>
            <Route exact path={`${EDIT_GROUP}/:id`} element={<AddOrEditGroup />}></Route>
            <Route exact path={USERS} element={<Users />}></Route>
            <Route exact path={ADD_USER} element={<AddOrEditUser />}></Route>
            <Route exact path={`${EDIT_USER}/:id`} element={<AddOrEditUser />}></Route>

            <Route exact path={MOBICOM_WEB} element={<ClubListing />}></Route>
            <Route exact path={ADD_CLUB} element={<AddClub />}></Route>
            <Route exact path={EDIT_CLUB + ":id"} element={<AddClub />}></Route>

            <Route exact path={PLATINUMCLUBNET} element={<PlatinumClubNet />}></Route>
            <Route exact path={PLATINUMCLUBNETADD} element={<PlatinumClubNetadd />}></Route>
            <Route exact path={LIST_BRAND_CATEGORY} element={<BrandCategoryList />}></Route>
            <Route exact path={ADD_BRAND_CATEGORY} element={<AddBrandCategory />}></Route>
            <Route exact path={EDIT_BRAND_CATEGORY + ":id"} element={<AddBrandCategory />}></Route>
            <Route exact path={CLUB_ONBOARDIN} element={<ClubOnboarding />}></Route>
            <Route exact path={EDIT_CLUB_ONBOARDING + ":id"} element={<ClubOnboarding />}></Route>
            <Route exact path={CLUB_ONBOARDING_LIST} element={<ClubOnboardingList />}></Route>
            <Route exact path={CLUB_PROFILES + ":id"} element={<ClubProfile />}></Route>

            <Route exact path={VIEW_CLUINFO + ":id"} element={<ViewClubinfo />}></Route>
            {/* Curated Member Events */}
            <Route exact path={CURATION_PROFILE} element={<CurationProfile />}></Route>
            {/* Current Club Events  */}
            <Route exact path={CURRENT_CLUB_EVENTS} element={<CurrentClubEvents />}></Route>
            <Route exact path={CLUB_EVENTS_PROFILE} element={<ClubEventsProfile />}></Route>
            {/* Private Events */}
            <Route exact path={PRIVATE_EVENTS_PROFILE} element={<PrivateEventsMenu />}></Route>
            {/* Promotions */}
            <Route exact path={PROMOTIONS} element={<Promotions />}></Route>
            {/* affiliated clubs */}
            <Route exact path={AFFILIATED_CLUBS} element={<AffiliatedClubs />}></Route>
            <Route exact path={ADD_AFFILIATED_CLUBS} element={<AddAffiliatedClubs />}></Route>
            <Route exact path={BLACKOUT_DATES} element={<BlackoutDates />}></Route>


            <Route exact path={SUBSCRIPTION_PRIVILEGES} element={<ListPrivilege />}></Route>
            <Route exact path={ADD_SUBSCRIPTION_PRIVILEGES} element={<SubscriptionPrivilege />}></Route>
            <Route exact path={EDIT_SUBSCRIPTION_PRIVILEGES + '/:id'} element={<SubscriptionPrivilege />}></Route>

            <Route exact path={MEMBERREVIEWLIST} element={<MemeberReviewList />}></Route>
            <Route exact path={VIEW_MEMBER_REVIEW + ':id'} element={<ViewMemeberReview />}></Route>
            <Route exact path={CLUB_TYPE_LIST} element={<ClubtypeList />}></Route>
            <Route exact path={ADD_CLUB_TYPE} element={<AddClubType />}></Route>
            <Route exact path={EDIT_CLUB_TYPE + ':id'} element={<AddClubType />}></Route>


            <Route exact path={SUBSCRIPTIONTYPELIST} element={<ListSubscriptionType />}></Route>
            <Route exact path={ADD_SUBSCRIPTIONTYPE} element={<AddsubscriptionType />}></Route>
            <Route exact path={EDIT_SUBSCRIPTIONTYPE + ':id'} element={<AddsubscriptionType />}></Route>

            <Route exact path={USER_SUBSCRIPTION_LIST} element={<UserSubscriptionList />}></Route>
          </Route>
        </Routes>
      }
    </Suspense>
  )
}

export default PrivateRoutes